<template>
    <div id="login">

		<!-- <img src="../../assets/faslearnBlack.png" width="140" height="42" style="position: fix; top: 5; left: 5"  alt="">
        	 -->
        <center><div style="width:140px; "><a href="/"><img src="../../assets/faslearnBlack.png" width="140" height="42"  alt=""></a></div></center>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-lg-4 col-sm-12" style="height:750px; width:500px;">
                    <iframe src="https://docs.google.com/forms/d/1r1huYvakx6SJE8VzkTXUQq5aAHUiJHpNNEvNqJvBQj0/viewform?edit_requested=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    <!-- <iframe src="https://docs.google.com/forms/d/15gUNmyuGraxUbgQkyqJrNz_M31nIL1FPWmHlNSUluhc/viewform?edit_requested=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
                    <!-- <iframe src="https://docs.google.com/forms/d/1d0d2LdNTeFCG4tyXKVFZwrOplzdG6VtY5uCdT7PT-nM/viewform?edit_requested=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
                   <!-- <iframe src="https://docs.google.com/forms/d/1LfVs888IBrWDceapNA3qIFQ8zs3WU4KTQM6KJe3CxQA/viewform?pli=1&pli=1&edit_requested=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
                   <!-- old https://docs.google.com/forms/d/1LfVs888IBrWDceapNA3qIFQ8zs3WU4KTQM6KJe3CxQA/edit -->
                </div>
                <div class="col-md-6 col-lg-8 back ">

                </div>
            </div>
				<div class="copy">© 2021 Faslearn</div>
        </div>
		
	</div>
</template>

<style scoped>
	h4{
		display: none;
	}
	.back{
		width: 100%;
		height: 700px;
		background: url(../../assets/12.jpg) no-repeat fixed;
        background-position-x: 500px;
	}

	
	
</style>
